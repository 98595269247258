@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Favorit';
  src: url('/fonts/favorit-bold.woff2') format('woff2'),
    url('/fonts/favorit-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Favorit';
  src: url('/fonts/favorit-regular.woff2') format('woff2'),
    url('/fonts/favorit-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tobias';
  src: url('/fonts/tobias-light.woff2') format('woff2'),
    url('/fonts/tobias-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 768px) {
  .md\:blur-background-image {
    position: relative;
  }
}

@media (min-width: 768px) {
  .md\:blur-background-image::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('/img/signup-background.svg');
    background-size: cover;
    background-position: top;
    filter: blur(5px);
    z-index: -1; /* Ensure the pseudo-element is behind the content */
  }
}

.error-page-background-image {
  background-image: url('/animations/wave.gif');
  background-repeat: no-repeat;
  background-position: center bottom;
  position: absolute;
  bottom: 0;
  background-size: 100% 20%;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}
